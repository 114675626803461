//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors

// Success
$success: #27c0cd;
$success-active: darken($success, 5%);
$success-light: if(isDarkMode(), #1c3238, #cdf6fa);
$success-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffa800, #fd7e14);
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
$warning-light: if(isDarkMode(), #392f28, #ffe5d0);
$warning-inverse: #ffffff;
