.alert_success {
  background-color: #27c0cd;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 10px rgba(63, 66, 83, 0.15);
  align-items: center;

  p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin: 0 0 0 12.5px;
    padding: 0;
  }

  rect,
  path {
    fill: white !important;
  }
}

.alert_error {
  background-color: #feebf0;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 10px rgba(63, 66, 83, 0.15);
  align-items: center;

  p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #f0426c;
    margin: 0 0 0 12.5px;
    padding: 0;
  }

  rect,
  path {
    fill: #f0426c !important;
  }
}
